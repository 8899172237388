import { AlertMessage, AlertType } from '@gv/ammo-astra';
import { AlertService } from '@gv/ammo-grome';
import { ERROR_AUTO_HIDE_DELAY } from '@/shared/raptor/constants';
import ApplicationContext from '@/shared/raptor/context/application-context';

export function showError(error: any) {
  let text = error;

  if (error.message) {
    text = error.message;
  }

  if (error.response && error.response.data.message) {
    text = error.response.status + ' ' + error.response.data.message;
  }

  const alert = new AlertMessage(AlertType.Error, text, true, ERROR_AUTO_HIDE_DELAY);
  AlertService.showAlert(ApplicationContext.getStore(), alert);
}

export function showWarning(text: any) {
  AlertService.showAlert(ApplicationContext.getStore(), new AlertMessage(AlertType.Warning, text, true));
}

export function showSuccess(text: string) {
  AlertService.showAlert(ApplicationContext.getStore(), new AlertMessage(AlertType.Success, text, true));
}
