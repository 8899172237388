import { Component, Inject, Provide, Vue } from 'vue-property-decorator';
import LoginService from '@/account/login.service';
import ErpEmployeeTrackerService from '@/entities/erp-employee-tracker/erp-employee-tracker.service';
import AlertService from '@/shared/alert/alert.service';

@Component
export default class GoAccount extends Vue {
  private inProgress = false;

  private inLoading = false;

  private fav = true;

  private menu = true;

  private message = true;

  private hints = true;
  private selectedOperator = null;
  private selectedDevice = null;

  items: any[] = [];

  @Inject('loginService')
  private loginService!: () => LoginService;

  public get operators() {
    return this.$store.getters['workCenterOperatorStore/operators'];
  }
  public get newOperators() {
    return this.$store.getters['workCenterOperatorStore/newOperators'];
  }

  public get devices() {
    return this.$store.getters['workCenterDeviceStore/devices'];
  }

  public getOperators() {
    return this.operators.length;
  }
  public getNewOperators() {
    return this.newOperators.length;
  }
  public getDevices() {
    return this.devices.length;
  }

  public showOperatorDescription(operator) {
    this.selectedOperator = operator.description;
  }
  public showDeviceDescription(device) {
    this.selectedDevice = device.id;
  }
  public get user() {
    return this.$store.getters['workCenterAccountStore/user'] ?? null;
  }

  public get authenticated(): boolean {
    return Object.keys(this.$store.getters['workCenterAccountStore/user'] ?? {}).length !== 0;
  }

  public logout(): void {
    // this.$store.dispatch('auth/logout')
    this.loginService().logout();
  }

  public get initials(): string {
    if (this.$store.getters['workCenterAccountStore/user'].data) {
      const tokens: string[] = this.$store.getters['workCenterAccountStore/user'].data.username.split(' ');

      if (tokens.length === 1) {
        return tokens[0].charAt(0).toUpperCase();
      } else {
        return tokens[0].charAt(0).toUpperCase() + tokens[tokens.length - 1].charAt(0).toUpperCase();
      }
    }
    return '#';
  }
}
