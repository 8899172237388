import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const ErpFolder = () => import('@/entities/erp-folder/erp-folder.vue');
const ErpFolderUpdate = () => import('@/entities/erp-folder/erp-folder-update.vue');
const ErpFolderDetails = () => import('@/entities/erp-folder/erp-folder-details.vue');

const ErpIdNumber = () => import('@/entities/erp-id-number/erp-id-number.vue');
const ErpIdNumberUpdate = () => import('@/entities/erp-id-number/erp-id-number-update.vue');
const ErpIdNumberDetails = () => import('@/entities/erp-id-number/erp-id-number-details.vue');

const ErpEmployeeTracker = () => import('@/entities/erp-employee-tracker/erp-employee-tracker.vue');
const ErpEmployeeTrackerUpdate = () => import('@/entities/erp-employee-tracker/erp-employee-tracker-update.vue');
const ErpEmployeeTrackerDetails = () => import('@/entities/erp-employee-tracker/erp-employee-tracker-details.vue');

const ErpShopFloorDevice = () => import('@/entities/erp-shop-floor-device/erp-shop-floor-device.vue');
const ErpShopFloorDeviceUpdate = () => import('@/entities/erp-shop-floor-device/erp-shop-floor-device-update.vue');
const ErpShopFloorDeviceDetails = () => import('@/entities/erp-shop-floor-device/erp-shop-floor-device-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

// SPE Begin
const ErpIdNumberOperators = () => import('@/entities/erp-id-number/spe/erp-id-number-operators.vue');
const ErpEmployeesTracker = () => import('@/entities/erp-employee-tracker/spe/erp-employees-tracker.vue');
const ErpManufacturingPlan = () => import('@/entities/erp-manufacturing-plan/erp-manufacturing-plan.vue');
const RaptorOperatorLanding = () => import('@/raptor/operator-landing/raptor-operator-landing.vue');
// SPE End

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'erp-folder',
      name: 'ErpFolder',
      component: ErpFolder,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-folder/new',
      name: 'ErpFolderCreate',
      component: ErpFolderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-folder/:erpFolderId/edit',
      name: 'ErpFolderEdit',
      component: ErpFolderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-folder/:erpFolderId/view',
      name: 'ErpFolderView',
      component: ErpFolderDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-id-number',
      name: 'ErpIdNumber',
      component: ErpIdNumber,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-id-number/new',
      name: 'ErpIdNumberCreate',
      component: ErpIdNumberUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-id-number/:erpIdNumberId/edit',
      name: 'ErpIdNumberEdit',
      component: ErpIdNumberUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-id-number/:erpIdNumberId/view',
      name: 'ErpIdNumberView',
      component: ErpIdNumberDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-employee-tracker',
      name: 'ErpEmployeeTracker',
      component: ErpEmployeeTracker,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-employee-tracker/new',
      name: 'ErpEmployeeTrackerCreate',
      component: ErpEmployeeTrackerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-employee-tracker/:erpEmployeeTrackerId/edit',
      name: 'ErpEmployeeTrackerEdit',
      component: ErpEmployeeTrackerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-employee-tracker/:erpEmployeeTrackerId/view',
      name: 'ErpEmployeeTrackerView',
      component: ErpEmployeeTrackerDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-shop-floor-device',
      name: 'ErpShopFloorDevice',
      component: ErpShopFloorDevice,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-shop-floor-device/new',
      name: 'ErpShopFloorDeviceCreate',
      component: ErpShopFloorDeviceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-shop-floor-device/:erpShopFloorDeviceId/edit',
      name: 'ErpShopFloorDeviceEdit',
      component: ErpShopFloorDeviceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'erp-shop-floor-device/:erpShopFloorDeviceId/view',
      name: 'ErpShopFloorDeviceView',
      component: ErpShopFloorDeviceDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
    // SPE Begin
    {
      path: '/shop-floor/manufacturing-plans',
      name: 'ErpManufacturingPlan',
      component: ErpManufacturingPlan,
      meta: { authorities: [Authority.SHOP_FOOR] },
    },
    {
      path: '/shop-floor/manufacturing-plans/:id/operator-landing',
      component: RaptorOperatorLanding,
      meta: { authorities: [Authority.SHOP_FOOR] },
    },
    // {
    //   path: '/shop-floor/manufacturing-plans/operator-landing/operators',
    //   name: 'ErpIdNumberOperators',
    //   component: ErpIdNumberOperators,
    //   meta: { authorities: [Authority.USER] },
    // },
    {
      path: '/shop-floor/operators',
      name: 'ErpEmployeesTracker',
      component: ErpEmployeesTracker,
      meta: { authorities: [Authority.USER] },
    },
    // SPE End
  ],
};
