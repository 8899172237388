/**
 * Formery "@/api/backend.js"
 */
import { Query } from '@gv/ammo-astra/dist/services/query/query';
import label from '@/shared/raptor/label';
import axios, { AxiosRequestConfig, AxiosStatic } from 'axios';
import QueryService from '@/shared/raptor/query/query.service';
import Qs from 'qs';
import { ICustomOperationTrack } from '@/shared/model/raptor/custom-operation-track.model';
import ApplicationContext from '@/shared/raptor/context/application-context';

const baseApiUrl = 'api/v1/manufacturing/shop-floor';

const axiosConfig: AxiosRequestConfig = {
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
};

export default class RaptorOperatorLandingService {
  constructor(private readonly axios: AxiosStatic, private readonly queryService: QueryService) {}

  /**
   * Raptor backend
   */

  /**
   * Gets work center parameterization
   * @returns {AxiosPromise<any>}
   */
  getProductionResource(workCenter: string, manufacturingPlan: string): Promise<any> {
    const params = { workCenter, manufacturingPlan };

    return this.queryService.query(Query.ERP_SAGE_X3_PRODUCTION_RESOURCE, params);
  }

  /**
   * Gets work center load
   */
  getProductionResourceLoad(manufacturingSite, workCenter, manufacturingPlan): Promise<any> {
    const params = { manufacturingSite, workCenter, manufacturingPlan };

    return this.queryService.query(Query.ERP_SAGE_X3_PRODUCTION_RESOURCE_LOAD, params);
  }

  trackOperation(payload: ICustomOperationTrack) {
    return axios.post(`${baseApiUrl}/custom-operation-tracks`, payload, axiosConfig);
  }

  trackMultipleOperation(payload: ICustomOperationTrack) {
    return axios.post(`${baseApiUrl}/custom-operation-tracks/creates`, payload, axiosConfig);
  }

  deleteMultipleOperation(payload) {
    return axios.post(`${baseApiUrl}/custom-operation-tracks/deletes`, payload, axiosConfig);
  }

  skipOperation(payload: ICustomOperationTrack) {
    return axios.post(`${baseApiUrl}/skipped-operation-tracks`, payload, axiosConfig);
  }

  skipMultipleOperation(payload: ICustomOperationTrack) {
    return axios.post(`${baseApiUrl}/skipped-operation-tracks/creates`, payload, axiosConfig);
  }

  deleteMultipleSkippedOperationTrack(payload) {
    return axios.post(`${baseApiUrl}/skipped-operation-tracks/deletes`, payload, axiosConfig);
  }

  /**
   * Please note that the document type is automatic set
   *
   * @param vcrnum0 Order no.
   * @param vcrlin0 Entry line no.
   * @param vcrseq0 Entry sequence no.
   * @param ztagnum0 Tag no.
   * @param zctx - Context
   * @param zorityp - Origin type
   * @param zori - Origin
   * @returns {Promise<AxiosResponse<any>>}
   */
  linkDocumentToWorkOrder(vcrnum0, vcrlin0, vcrseq0, ztagnum0, zctx, zorityp, zori) {
    return axios.post(`${baseApiUrl}/link-documents-to-tags`, {
      vcrtyp0: 10,
      vcrnum0: vcrnum0,
      vcrlin0: vcrlin0,
      vcrseq0: vcrseq0,
      ztagnum0: ztagnum0,
      zctx0: zctx,
      zorityp0: zorityp,
      zori0: zori,
    });
  }

  printLabel(payload) {
    const environment = SHOP_FLOOR_PRINTER_MODE;
    const destination: string = ApplicationContext.getStore().getters['workCenterDeviceStore/devices'].find(
      device => device.destination ?? '' !== ''
    ).destination;

    return axios.post(`${baseApiUrl}/coding-marking-label/print/(environment=${environment},printer=${destination})`, payload);
  }
}
