import { Module } from 'vuex';
import { IErpEmployeeTracker } from '@/shared/model/erp-employee-tracker.model';
import { AxiosSingleton } from '@/shared/config/axios-interceptor';
import ErpEmployeeTrackerService from '@/entities/erp-employee-tracker/erp-employee-tracker.service';
import { bottomSheetStoreMutationTypes } from '@/shared/config/store/raptor/mutation-types/types';
const api = new ErpEmployeeTrackerService(AxiosSingleton.getInstance());
export interface WorkCenterOperatorStateStorable {
  operators: Array<IErpEmployeeTracker>;
  newOperators: Array<IErpEmployeeTracker>;
}

export const defaultWorkCenterOperatorState: WorkCenterOperatorStateStorable = {
  operators: [],
  newOperators: [],
};

/**
 * Asynchronous process
 */
const actions = {
  /**
   * Fetch device list from api
   * @param commit
   * @param payload
   */
  fetchData(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .workCenterOperators(payload.workCenter)
        .then(response => {
          context.commit('operators', response.data);
          context.dispatch(bottomSheetStoreMutationTypes.reset, context.state.operators, { root: true }).then(response => {
            if (Array.isArray(response)) {
              response.forEach(it => console.debug(`WorkCenter ${it.workCenter} added to bottom sheet.`));
            } else {
              console.debug(`WorkCenter ${response.workCenter} not added to bottom sheet.`);
            }
          });
          resolve(response);
        })
        .catch(error => {
          // AlertUtils.showError(error);
          reject(error);
        });
    });
  },
};

export const workCenterOperatorStore: Module<WorkCenterOperatorStateStorable, any> = {
  state: { ...defaultWorkCenterOperatorState },
  getters: {
    operators: state => state.operators,
    newOperators: state => state.newOperators,
  },
  mutations: {
    enterNewWorkCenter(state, newOperators) {
      newOperators.id = Math.floor(Math.random() * (999 - 100 + 1) + 100);
      newOperators.description = 'Primeiro Um último';
      newOperators.employeeId = newOperators.id;
      state.newOperators.push(newOperators);
    },

    exitWorkCenter(state, operator) {
      const index = state.operators.indexOf(operator);
      if (index !== -1) {
        state.operators.splice(index, 1);
      }
    },
    exitNewWorkCenter(state, exitNewOperator) {
      const index = state.newOperators.indexOf(exitNewOperator);
      if (index !== -1) {
        state.newOperators.splice(index, 1);
      }
    },
    operators(state, payload) {
      state.operators = payload;
    },
    newOperators(state, payload) {
      state.operators = payload;
    },
  },
  actions,
  namespaced: true,
};
