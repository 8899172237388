import { IOperatorLandingContext } from '@/shared/model/raptor/messaging/support/operator-landing-context.model';
import { AxiosError, AxiosResponse } from 'axios';

export interface IPublishPayload {
  context?: IOperatorLandingContext;
  response?: AxiosResponse | AxiosError;
  getEvaluationContext(): { [k: string]: any };
}

export class PublishPayload implements IPublishPayload {
  constructor(public context?: any, public response?: AxiosResponse | AxiosError) {}

  public getEvaluationContext(): { [k: string]: any } {
    if (this.response instanceof AxiosError) {
      return {
        payload: {
          message: this.context.getInnerMessage(),
          response: {
            ...this.response.response,
            // TODO: remove when the object is replaced with data
            // Gets the request payload for axios
            object: JSON.parse(this.response.response.config.data),
          },
        },
      };
    } else {
      return {
        payload: {
          message: this.context.getInnerMessage(),
          response: {
            ...this.response,
            // TODO: remove when the object is replaced with data
            object: this.response.data,
          },
        },
      };
    }
  }
}
