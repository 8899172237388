import Vue from 'vue';
import Component from 'vue-class-component';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);
import Router, { RouteConfig } from 'vue-router';

// const Home = () => import('@/core/home/home.vue');       // SPE Home
const Home = () => import('@/core/raptor/go-login/go-login.vue'); // SPE Home
const Error = () => import('@/core/error/error.vue');
/* SPE Begin
import admin from '@/router/admin';
SPE End */
import entities from '@/router/entities';
import { Authority } from '@/shared/security/authority';

/* SPE Begin
import pages from '@/router/pages';
SPE End */
import kiosk from '@/router/kiosk';

const RaptorOperatorLanding = () => import('@/raptor/dashboard/raptor-dashboard.vue');

Vue.use(Router);

// prettier-ignore
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/forbidden',
      name: 'Forbidden',
      component: Error,
      meta: { error403: true }
    },
    {
      path: '/not-found',
      name: 'NotFound',
      component: Error,
      meta: { error404: true }
    },
    /* SPE Begin
    ...admin,
    SPE End */
    ...kiosk,
    entities,
    /* SPE Begin
    ...pages
    SPE End */
    {
      path: '/dashboard',
      name: 'RaptorDashboard',
      component: RaptorOperatorLanding,
      meta: { authorities: [Authority.SHOP_FOOR] },
    }
  ]
});

export default router;
