import axios, { AxiosRequestConfig, AxiosStatic } from 'axios';

import { IErpEmployeeTracker } from '@/shared/model/erp-employee-tracker.model';
import * as Qs from 'qs';

const baseApiUrl = 'api/v1/manufacturing/shop-floor/employee-trackers';

const axiosConfig: AxiosRequestConfig = {
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
};

const today = new Date();
today.setHours(1, 0, 0, 0);

export default class ErpEmployeeTrackerService {
  // BEGIN SPE
  constructor(private axios: AxiosStatic) {}

  /**
   * Finds work center devices by work center and manufacturing site
   * @param workCenter
   */
  public workCenterOperators(workCenter): Promise<any> {
    return axios.get(
      `${baseApiUrl}?workCenter.equals=${workCenter}&startTime.greaterThan=${today.toISOString()}&endTime.equals=1753-01-01T00:36:45.000000000Z`,
      axiosConfig
    );
  }
  // END SPE

  public find(id: number): Promise<IErpEmployeeTracker> {
    return new Promise<IErpEmployeeTracker>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IErpEmployeeTracker): Promise<IErpEmployeeTracker> {
    return new Promise<IErpEmployeeTracker>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IErpEmployeeTracker): Promise<IErpEmployeeTracker> {
    return new Promise<IErpEmployeeTracker>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IErpEmployeeTracker): Promise<IErpEmployeeTracker> {
    return new Promise<IErpEmployeeTracker>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
