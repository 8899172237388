import * as MqttHeaders from '@/shared/raptor/messaging/support/mqtt-web-socket-headers';
import * as OpcUaHeaders from '@/shared/raptor/messaging/support/opcua-web-socket-headers';
import * as HttpHeaders from '@/shared/raptor/messaging/support/http-web-socket-headers';
import { TopicAction } from '@/shared/raptor/messaging/support/topic-actions';

export enum ComponentType {
  MQTT = 'mqtt',
  HTTP = 'http',
  OPCUA = 'opcua',
}

export class ComponentUtil {
  static getMessageConstant(component: ComponentType, topicAction: TopicAction): string {
    console.log(component, topicAction);
    switch (component) {
      case ComponentType.MQTT:
        return MqttHeaders[topicAction];
      case ComponentType.HTTP:
        return HttpHeaders[topicAction];
      case ComponentType.OPCUA:
        return OpcUaHeaders[topicAction];
      default:
        throw new Error('Invalid component type');
    }
  }
}
