import { Module } from 'vuex';
import { AxiosSingleton } from '@/shared/config/axios-interceptor';
import RaptorWorkCenterDeviceService from '@/raptor/operator-landing/devices/service/raptor-work-center-device.service';
import { StatusType } from '@/shared/model/raptor/status-type.model';
import ApplicationContext from '@/shared/raptor/context/application-context';
import { bottomSheetStoreMutationTypes, yakovlevStoreMutationTypes } from '@/shared/config/store/raptor/mutation-types/types';
import dispatcherMessage from '@/shared/raptor/messaging/handler/dispatcher-message';
import templateParser from '@/shared/raptor/messaging/support/template-parser';
import * as AlertUtils from '@/shared/raptor/util/alert-utils';
import { IErpShopFloorDevice } from '@/shared/model/erp-shop-floor-device.model';
import { ComponentType } from '@/shared/model/enumerations/component-type.model';

const api = new RaptorWorkCenterDeviceService(AxiosSingleton.getInstance());

export interface workCenterDeviceStateStorable {
  stages: { [k: string]: any };
  steps: { [k: string]: any };
  devices: Array<IErpShopFloorDevice>;
}

/**
 * A stage block defines a conceptually distinct subset of tasks performed through the entire Pipeline.
 *
 *
 * "stages" are called when start, pause, skip, complete, etc. happen. All devices are scanned for the specified stage,
 * and stage block get executed by the dispatcher.
 *
 * The "message-received" stage is called when a message is received from the mqtt broker. The device is found, the
 * stage block is found by matching the payload header endpoint with the stage block name, finally the stage block get
 * executed by the dispatcher.
 *
 *
 *
 *
 *
 * Actions
 *      mqtt_topic              -> triggers a publish message into device.topic
 *                                 Requires topic and message arguments
 *      mqtt_receivedTopic      -> the segments
 */
export const defaultWorkCenterDeviceState: workCenterDeviceStateStorable = {
  stages: {
    start: 'start',
    messageReceived: 'message-received',
  },
  steps: {
    sendMqttMessage: 'send-mqtt-message',
    linkDocumentToWorkOrder: 'link-document-to-work-order',
    completeWithoutOperationTrack: 'complete-without-operation-track',
    operationTrack: 'operation-track',
    operationTrackWithoutContext: 'operation-track-without-context',
    printLabel: 'print-label',
  },
  devices: [],
};

/**
 * Asynchronous process
 */
const actions = {
  /**
   * Fetch device list from api
   * @param commit
   * @param payload
   */
  fetchData(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .workCenterDevices(payload.workCenter, payload.manufacturingSite)
        .then(response => {
          context.commit('devices', response.data);

          const devices = context.state.devices.map(it => {
            return {
              id: it.id,
              icon: it.icon || 'mdi-link-box',
              type: StatusType.Device,
              color: 'success',
              title: it.description,
              connected: true,
              countDownToReconnect: false,
              reconnect: false,
              reconnectInterval: 0,
              message: ApplicationContext.getI18n().t('raptor.operatorLanding.connected'),
              error: null,
            };
          });
          context.dispatch(bottomSheetStoreMutationTypes.reset, devices, { root: true }).then(response => {
            if (Array.isArray(response)) {
              response.forEach(it => console.debug(`Device ${it.id} added to bottom sheet.`));
            } else {
              console.debug(`Device ${response.id} added to bottom sheet.`);
            }
          });
          resolve(response);
        })
        .catch(error => {
          AlertUtils.showError(error);
          reject(error);
        });
    });
  },

  /**
   * Subscribe mqtt channels
   * @param commit
   * @param payload
   */
  subscribe({ dispatch, state }, payload) {
    // Main topic subscription
    dispatch(
      yakovlevStoreMutationTypes.subscribeTopic,
      templateParser.fill(`raptor/${payload.manufacturingPlan}/${payload.workCenter}`, { payload }),
      { root: true }
    );

    // Devices subscription to output channel
    const subscribableComponentTypes = Object.values(ComponentType).map(it => it.toLowerCase());
    state.devices
      .filter((device: IErpShopFloorDevice) => subscribableComponentTypes.includes(device.type))
      .forEach((device: IErpShopFloorDevice) => {
        dispatch(yakovlevStoreMutationTypes.subscribeTopic, templateParser.fill(device.output, { device, payload }), { root: true });
      });
  },

  /**
   * Received from WS
   * @param commit
   * @param data messageEvent
   */
  messageReceived({ commit }, data) {
    dispatcherMessage.messageReceived(data);
  },

  start({ commit }, data) {
    dispatcherMessage.start(data);
  },

  complete({ commit }, data) {
    dispatcherMessage.complete(data);
  },

  /**
   * Sets active state
   * @param commit
   * @param payload
   */
  update(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .workCenterDeviceUpdate(payload)
        .then(response => {
          context.commit('updateDevice', response.data);
          resolve(response);
        })
        .catch(error => {
          AlertUtils.showError(error);
          reject(error);
        });
    });
  },
};

export const workCenterDeviceStore: Module<workCenterDeviceStateStorable, any> = {
  state: { ...defaultWorkCenterDeviceState },
  getters: {
    stages: state => state.stages,
    steps: state => state.steps,
    devices: state => state.devices,
  },
  mutations: {
    updateDevice(state, payload) {
      state.devices.forEach((it, i) => {
        if (it.id === payload.id) {
          state.devices[i] = payload;
        }
      });
    },
    devices(state, payload) {
      state.devices = payload;
    },
  },
  actions,
  namespaced: true,
};
