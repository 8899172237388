import messageHandlerSupport from '@/shared/raptor/messaging/support/message/message-handler-support';
// import store from "@/store"
// import { notifier } from "@/mixins/notifier"
import { yakovlevStoreMutationTypes, operatorLandingStoreMutationTypes } from '@/shared/config/store/raptor/mutation-types/types';
import { MessageType } from '@/shared/raptor/messaging/support/message/message-type';
// import { DeliveryStatus } from '@/shared/raptor/messaging/support/message/message-flow';
import { MessageStatus } from '@/shared/raptor/messaging/support/message/message-status';
import { ResponseStatusError } from '@/shared/raptor/messaging/support/error/response-status-error';
import ApplicationContext from '@/shared/raptor/context/application-context';
import * as AlertUtils from '@/shared/raptor/util/alert-utils';
import { IOperatorLandingContext } from '@/shared/model/raptor/messaging/support/operator-landing-context.model';
import { IPublishPayload } from '@/shared/model/raptor/messaging/support/publish-payload.model';

/**
 * Publish action
 * @param device
 * @param stage
 * @param step
 * @param type request or response
 * @param payload
 */
function publish(device, stage, step, type, payload: IPublishPayload) {
  if (process.env.NODE_ENV !== 'production') {
    console.debug(
      `Request to publish : device=${device.id}, stage=${stage.name}, step=${step.name}, type=${type}, payload=${JSON.stringify(payload)}`
    );
  }
  let message: { [k: string]: any };
  try {
    message = messageHandlerSupport.build(device, stage, step, type, payload);
  } catch (e) {
    const s = `Error building message for device ${device.id} stage ${stage.name} step ${step.action}<br>Details: ${e.message}`;
    console.error(s);
    throw new Error(s);
  }
  ApplicationContext.getStore()
    .dispatch(yakovlevStoreMutationTypes.publishTopic, message)
    .then(r => {
      console.info(`Message published ${r}`);
    })
    .catch(error => {
      throw error;
    });
}

/**
 * Complete action
 * @param device
 * @param stage
 * @param step
 * @param payload
 */
function complete(device, stage, step, payload: IOperatorLandingContext) {
  if (process.env.NODE_ENV !== 'production') {
    console.debug(`Request to complete : device=${device.id}, stage=${stage.name}, step=${step.name}, payload=${JSON.stringify(payload)}`);
  }
  ApplicationContext.getStore()
    .dispatch(operatorLandingStoreMutationTypes.complete, { ...payload.getInnerPayload(), data: payload.data })
    .then()
    .catch(error => {
      AlertUtils.showError(error);
    });
}

/**
 * Complete without operation track
 * @param device
 * @param stage
 * @param step
 * @param payload
 */
function completeWithoutOperationTrack(device, stage, step, payload: IOperatorLandingContext) {
  if (process.env.NODE_ENV !== 'production') {
    console.debug(
      `Request to update a CompleteWithoutOperationTrack: device=${device.id}, stage=${stage.name}, step=${step.name}, payload=${JSON.stringify(
        payload
      )}`
    );
  }
  ApplicationContext.getStore()
    .dispatch(operatorLandingStoreMutationTypes.completeWithoutOperationTrack, { ...payload.getInnerPayload(), data: payload.data })
    .then()
    .catch(error => {
      AlertUtils.showError(error);
    });
}

/**
 * Complete with operation track
 * @param device
 * @param stage
 * @param step
 * @param payload
 */
function operationTrack(device, stage, step, payload) {
  if (process.env.NODE_ENV !== 'production') {
    console.debug(
      `Request to save CustomOperationTrack : device=${device.id}, stage=${stage.name}, step=${step.name}, payload=${JSON.stringify(
        payload
      )}`
    );
  }
  ApplicationContext.getStore()
    .dispatch(operatorLandingStoreMutationTypes.complete, { ...payload.getInnerPayload(), data: payload.data })
    .then()
    .catch(error => {
      AlertUtils.showError(error);
    });
}

/**
 * Print label
 * @param device
 * @param stage
 * @param step
 * @param payload
 */
function printLabel(device, stage, step, payload) {
  if (process.env.NODE_ENV !== 'production') {
    console.debug(
      `Request to print label : device=${device.id}, stage=${stage.name}, step=${step.name}, payload=${JSON.stringify(
        payload
      )}`
    );
  }
  ApplicationContext.getStore()
    .dispatch(operatorLandingStoreMutationTypes.printLabel, { ...payload.getInnerPayload(), data: payload.data })
    .then()
    .catch(error => {
      AlertUtils.showError(error);
    });
}

/**
 * Link document to work order
 * @param device
 * @param stage
 * @param step
 * @param payload
 */
function linkDocumentToWorkOrder(device, stage, step, payload: IOperatorLandingContext) {
  if (process.env.NODE_ENV !== 'production') {
    console.debug(
      `Request to save LinkDocumentToWorkOrder : device=${device.id}, stage=${stage.name}, step=${step.name}, payload=${JSON.stringify(
        payload
      )}`
    );
  }
  // Redefine completed quantity to one, automation is sending "Amount of total completed quantity" instead of
  // "total completed quantity"
  const redefinedPayload = { ...payload.getInnerPayload(), data: payload.data };
  redefinedPayload['CPLQTY_0'] = 1;

  ApplicationContext.getStore()
    .dispatch(operatorLandingStoreMutationTypes.linkDocumentToWorkOrder, redefinedPayload)
    .then(response => {
      // Update request with response status
      ApplicationContext.getStore()
        .dispatch(yakovlevStoreMutationTypes.updateMessageStatus, {
          uuid: payload.getInnerHeaders().uuid,
          response: {
            status: response.status,
            message: response.statusText,
          },
        })
        .then();
      return response;
    })
    .then(response => {
      publish(device, stage, step, MessageType.RESPONSE, messageHandlerSupport.prepareResponse(payload, response));
      complete(device, stage, step, payload);
    })
    .catch(error => {
      publish(device, stage, step, MessageType.RESPONSE, messageHandlerSupport.prepareResponse(payload, error));
      // Update request with response status
      ApplicationContext.getStore()
        .dispatch(yakovlevStoreMutationTypes.updateMessageStatus, {
          uuid: payload.getInnerHeaders().uuid,
          response: {
            status: error.response.status,
            message: error.response.message,
          },
        })
        .then();
    });
}

function hydrateStatusAndMessage(error) {
  let status = MessageStatus.BAD_REQUEST.value;
  let message = MessageStatus.BAD_REQUEST.reasonPhrase;

  const response = error.hasOwnProperty('response') ? error.response : null;

  if (error instanceof ResponseStatusError) {
    status = response.status;
    message: response.data.message !== '' ? response.data.message : response.statusText;
  } else if (error instanceof Error) {
    // AXIOS error
    if (error.hasOwnProperty('response')) {
      if (response.hasOwnProperty('status')) {
        status = response.status;
      }

      if (response.hasOwnProperty('statusText')) {
        message = response.statusText;
      }
      if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message') && response.data.message !== '') {
        message = response.data.message;
      }
    } else {
      message = error.message;
    }
  }
  return { status, message };
}

export default {
  publish,
  complete,
  printLabel,
  operationTrack,
  linkDocumentToWorkOrder,
  completeWithoutOperationTrack,
};
