import axios, { AxiosRequestConfig, AxiosStatic } from 'axios';
import * as Qs from 'qs';

const baseApiUrl = 'api/v1/manufacturing/shop-floor/work-center-devices';

const axiosConfig: AxiosRequestConfig = {
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
};

export default class RaptorWorkCenterDeviceService {
  constructor(private axios: AxiosStatic) {}

  /**
   * Finds work center devices by work center and manufacturing site
   * @param workCenter
   * @param manufacturingSite
   */
  workCenterDevices(workCenter, manufacturingSite) {
    return axios.get(`${baseApiUrl}?workCenter=${workCenter}&manufacturingSite=${manufacturingSite}`, axiosConfig);
  }

  workCenterDeviceStatistics(endpoint, payload) {
    return axios.post(`${baseApiUrl}/${endpoint}`, payload, axiosConfig);
  }

  workCenterDeviceUpdate(payload) {
    return axios.put(baseApiUrl, payload, axiosConfig);
  }
}
