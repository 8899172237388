import { Module } from 'vuex';
import { IOperatorLandingLoading, OperatorLandingLoading } from '@/shared/config/store/raptor/model/operator-landing-loading.model';
import shopfloor from '@/shared/raptor/shopfloor';
import zmfgope from '@/shared/raptor/zmfgope';
import label from '@/shared/raptor/label';
import { progressStoreMutationTypes } from '@/shared/config/store/raptor/mutation-types/types';
import * as type from '@/shared/config/store/raptor/mutation-types/operator-landing-types';
import RaptorOperatorLandingService from '@/raptor/operator-landing/service/raptor-operator-landing.service';
import { AxiosSingleton } from '@/shared/config/axios-interceptor';
import QueryService from '@/shared/raptor/query/query.service';
import * as AlertUtils from '@/shared/raptor/util/alert-utils';
import ApplicationContext from '@/shared/raptor/context/application-context';
import { ResponseStatusError } from '@/shared/raptor/messaging/support/error/response-status-error';
import { MessageStatus } from '@/shared/raptor/messaging/support/message/message-status';
import RaptorOperatorLandingValidationService from '@/raptor/operator-landing/service/raptor-operator-landing-validation.service';
import { EventBus } from '@gv/ammo-astra/dist';
import { FromOperatorLandingLine, ICustomOperationTrack } from '@/shared/model/raptor/custom-operation-track.model';

const queryService = new QueryService(AxiosSingleton.getInstance());
const api = new RaptorOperatorLandingService(AxiosSingleton.getInstance(), queryService);

export interface OperatorLandingStateStorable {
  loading: IOperatorLandingLoading;
  rowData: any[];
  workCenter: null | string;
  manufacturingPlan: null | string;
  tagLimit: number;
  tags: any[];
}

export const defaultOperatorLandingState: OperatorLandingStateStorable = {
  loading: new OperatorLandingLoading(false, false, false, false, false, false, false),
  rowData: [],
  workCenter: null,
  manufacturingPlan: null,

  // Buffer previous 10th tags
  tagLimit: 9,
  tags: [],
};

export const getters = {
  manufacturingPlan: state => {
    return state.manufacturingPlan;
  },

  rowData: state => {
    return state.rowData;
  },

  workCenter: state => {
    return state.workCenter;
  },

  current: state => {
    if (!state.rowData) {
      return null;
    }

    let i = -1;
    let j = -1;
    let found = false;
    let current = null;

    // Use vanilla js for better performance and the ability to break the loop
    for (i = 0; i < state.rowData.length; i++) {
      for (j = 0; j < state.rowData[i].children.length; j++) {
        const completed = state.rowData[i].children[j]['ZMFGOPETRK__ZAMTCPLQTY_0'];
        const skipped = state.rowData[i].children[j]['ZMFGOPETRK__ZAMTSKPCPLQTY_0'];
        const expected = state.rowData[i].children[j]['MFGITM__EXTQTY_0'];

        if (completed + skipped < expected) {
          found = true;
          break;
        }
      }

      // Child index found set parent index and break
      if (found) {
        current = {
          indexes: {
            parent: i,
            child: j,
          },
          rowData: state.rowData[i].children[j],
        };
        break;
      }
    }

    return current;
  },

  byOrderNoAndOperationAndOperationSplit: state => payload => {
    if (!payload.hasOwnProperty('orderNo')) throw new Error('Order No is not defined');
    if (!payload.hasOwnProperty('operation')) throw new Error('Operation is not defined');
    if (!payload.hasOwnProperty('operationSplit')) throw new Error('Operation split is not defined');

    if (isNaN(payload.operation)) throw new Error('Operation is not a number');
    if (isNaN(payload.operationSplit)) throw new Error('Operation split is not a number');

    const orderNo = payload.orderNo;
    const operation = Math.trunc(payload.operation);
    const operationSplit = Math.trunc(payload.operationSplit);

    let i = -1;
    let j = -1;
    let found = false;
    let current = null;

    for (i = 0; i < state.rowData.length; i++) {
      for (j = 0; j < state.rowData[i].children.length; j++) {
        if (
          state.rowData[i].children[j]['MFGITM__MFGNUM_0'] === orderNo &&
          state.rowData[i].children[j]['ZOPEWRKF__OPENUM_0'] === operation &&
          state.rowData[i].children[j]['ZOPEWRKF__OPESPLNUM_0'] === operationSplit
        ) {
          found = true;
          break;
        }
      }

      // Child index found set parent index and break
      if (found) {
        current = {
          indexes: {
            parent: i,
            child: j,
          },
          rowData: state.rowData[i].children[j],
        };
        break;
      }
    }

    return current;
  },

  lineProgress: (state, getters) => {
    const current = getters.current;

    if (!current) {
      return '0/0';
    }

    const i = current.indexes.parent;
    let j;
    let completed = 0;
    let skipped = 0;
    let expected = 0;

    for (j = 0; j < state.rowData[i].children.length; j++) {
      completed += state.rowData[i].children[j]['ZMFGOPETRK__ZAMTCPLQTY_0'];
      skipped += state.rowData[i].children[j]['ZMFGOPETRK__ZAMTSKPCPLQTY_0'];
      expected += state.rowData[i].children[j]['MFGITM__EXTQTY_0'];
    }

    return `${skipped + completed}/${expected}`;
  },

  totalProgress: state => {
    if (!state.rowData || state.rowData.length === 0) {
      return '0/0';
    }

    let i;
    let j;
    let completed = 0;
    let skipped = 0;
    let expected = 0;

    for (i = 0; i < state.rowData.length; i++) {
      for (j = 0; j < state.rowData[i].children.length; j++) {
        completed += state.rowData[i].children[j]['ZMFGOPETRK__ZAMTCPLQTY_0'];
        skipped += state.rowData[i].children[j]['ZMFGOPETRK__ZAMTSKPCPLQTY_0'];
        expected += state.rowData[i].children[j]['MFGITM__EXTQTY_0'];
      }
    }

    return `${skipped + completed}/${expected}`;
  },

  willOverTrack: state => payload => {
    const rowData = state.rowData[payload.data.indexes.parent].children[payload.data.indexes.child];
    const completed = rowData['ZMFGOPETRK__ZAMTCPLQTY_0'];
    const skipped = rowData['ZMFGOPETRK__ZAMTSKPCPLQTY_0'];
    const expected = rowData['MFGITM__EXTQTY_0'];

    return completed + skipped + payload['CPLQTY_0'] > expected;
  },

  loading: state => {
    return state.loading;
  },

  tags: state => {
    return state.tags;
  },
};
/**
 * Asynchronous process
 */
const actions = {
  manufacturingPlan(context, payload) {
    context.commit(type.manufacturingPlan, payload.manufacturingPlan);
  },

  fetchData(context, payload) {
    const i18n = ApplicationContext.getI18n();

    const manufacturingSite = payload.manufacturingSite;
    const workCenter = payload.workCenter;
    const manufacturingPlan = payload.manufacturingPlan;

    let productionResourceLoadData = null;
    let productionResourceData = null;

    context.commit(type.loading, { key: 'main', value: true });
    context.commit(progressStoreMutationTypes.indeterminate, true, { root: true });
    context.commit(progressStoreMutationTypes.active, true, { root: true });

    const productionResourcePromise = api
      .getProductionResource(workCenter, manufacturingPlan)
      .then(response => {
        productionResourceData = response.data[0];
      })
      .catch(error => {
        AlertUtils.showError(error);
      });

    const productionResourceLoadPromise = api
      .getProductionResourceLoad(manufacturingSite, workCenter, manufacturingPlan)
      .then(response => {
        productionResourceLoadData = response.data;
      })
      .catch(error => {
        AlertUtils.showError(error);
      });

    Promise.all([productionResourcePromise, productionResourceLoadPromise])
      .then(() => {
        context.commit(type.workCenter, productionResourceData);

        context.commit(
          type.rowData,
          shopfloor.orderBy(
            shopfloor.groupBy(productionResourceLoadData, zmfgope.buildGroupKey),
            zmfgope.buildGroups,
            zmfgope.buildGroupsOrderBy(productionResourceData)
          )
        );

        context.commit(type.loading, { key: 'main', value: false });
        context.commit(progressStoreMutationTypes.active, false, { root: true });

        if (!productionResourceLoadData.length) {
          AlertUtils.showError(
            i18n.t('raptor.operatorLanding.noDataIsAvailableFor', {
              '0': manufacturingPlan,
            })
          );
        }
      })
      .catch(error => {
        context.commit(type.loading, { key: 'main', value: false });
        context.commit(progressStoreMutationTypes.active, false, { root: true });
      });
  },

  /**
   *
   *
   * @param context
   * @param payload Object should has two properties current and ZTAGNUM_0
   * @returns {Promise<unknown>}
   */
  linkDocumentToWorkOrder(context, payload) {
    const i18n = ApplicationContext.getI18n();
    const validationService = new RaptorOperatorLandingValidationService(i18n);

    return new Promise((resolve, reject) => {
      const method = i18n.t('raptor.operatorLanding.pairTagShort');

      const validator = validationService.linkDocumentToWorkOrder(payload);
      if (validator.fails()) {
        const errorMessage = RaptorOperatorLandingValidationService.errorsMessage(validator);
        AlertUtils.showError(`${method}:<br>&nbsp;&nbsp;&nbsp;&nbsp;${errorMessage}`);

        throw new ResponseStatusError(errorMessage, MessageStatus.BAD_REQUEST);
      }

      if (context.getters[type.willOverTrack](payload)) {
        const errorMessage = i18n.t('raptor.operatorLanding.requestFailedDueToOverTrack');
        AlertUtils.showError(`${method}:<br>&nbsp;&nbsp;&nbsp;&nbsp;${errorMessage}`);

        throw new ResponseStatusError(errorMessage, MessageStatus.BAD_REQUEST);
      }

      context.commit(type.loading, { key: 'linkDocumentToWorkOrder', value: true });
      api
        .linkDocumentToWorkOrder(
          payload['MFGNUM_0'],
          payload['OPENUM_0'],
          payload['OPESPLNUM_0'],
          payload['ZTAGNUM_0'],
          JSON.stringify({ ZLNKDOCTAG__ZTAGNUM_0: payload['ZTAGNUM_0'], ...payload.data.rowData }),
          'device',
          '0001'
        )
        .then(response => {
          context.commit(type.loading, { key: 'linkDocumentToWorkOrder', value: false });

          context.commit(type.addTag, response.data);

          AlertUtils.showSuccess(
            `${i18n.t('raptor.operatorLanding.successfullyLinkDocumentToWorkOrder', {
              '0': response.data['ztagnum0'],
              '1': response.data['vcrnum0'],
            })}`
          );
          resolve(response);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'linkDocumentToWorkOrder', value: false });

          // const errorMessage = erpWebServiceMessageUtil.buildErrorMessage(error.response)
          let errorMessage = error.response.data.message;

          // 409 Conflict
          if (error.response.status === 409) {
            // Cannot insert duplicate key row in object 'GV.ZLNKDOCTAG' with unique index 'ZLNKDOCTAG_ZLDT0'. The duplicate key value is (RFID-TAG-ID-0007).

            const regex = /The duplicate key value is \((.*?)\)./g;
            const matches = errorMessage.match(regex);

            if (matches) {
              const regex4Tag = /(?<=The duplicate key value is \().*(?=\).)/g;
              const tag = errorMessage.match(regex4Tag);

              errorMessage = matches;

              // Check if it's in last tags array
              const index = context.state.tags.findIndex(it => it.ztagnum0 === tag[0]);

              if (tag && index !== -1) {
                let lastSeen;

                switch (index) {
                  case 0:
                    lastSeen = i18n.t('raptor.operatorLanding.tagEqualToLastOne');
                    break;
                  default:
                    lastSeen = i18n.t('raptor.operatorLanding.tagEqualToTagPrevious', { '0': index + 1 });
                }
                errorMessage += '<br>' + lastSeen;
              }
            }
          }
          AlertUtils.showError(error + '<br>' + errorMessage);
          reject(error);
        });
    });
  },

  printLabel(context, payload) {
    const i18n = ApplicationContext.getI18n();

    return new Promise((resolve, reject) => {
      if (!payload.data) {
        const message = i18n.t('raptor.operatorLanding.undefinedOrNull', { '0': i18n.t('data') }).toString();
        reject(new Error(message));
        return;
      }

      context.commit(type.loading, { key: 'printLabel', value: true });
      api
        .printLabel(payload.data.rowData)
        .then(response => {
          context.commit(type.loading, { key: 'printLabel', value: false });

          AlertUtils.showSuccess(
            `${i18n.t('raptor.operatorLanding.successfullySentToThePrinter', {
              '0': label.product(payload.data.rowData),
            })}`
          );
          resolve(response);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'printLabel', value: false });
          AlertUtils.showError(error);
          reject(error);
        });
    });
  },

  /**
   * Complete
   * @param context
   * @param payload Object should has two properties current and CPLQTY_0
   * @returns {Promise<unknown>}
   */
  complete(context, payload) {
    const i18n = ApplicationContext.getI18n();
    const validationService = new RaptorOperatorLandingValidationService(i18n);

    return new Promise((resolve, reject) => {
      const method = i18n.t('raptor.operatorLanding.complete');

      const validator = validationService.complete(payload);
      if (validator.fails()) {
        throw new Error(`${method}:<br>${RaptorOperatorLandingValidationService.errorsMessage(validator)}`);
      }
      context.commit(type.loading, { key: 'complete', value: true });
      const track = FromOperatorLandingLine(payload.data.rowData, payload['CPLQTY_0'], payload['ZTAGNUM_0']);
      api
        .trackOperation(track)
        .then(response => {
          context.commit(type.updateCompletedQuantity, {
            parentIndex: payload.data.indexes.parent,
            childIndex: payload.data.indexes.child,
            completedQuantity: payload['CPLQTY_0'],
          });
          context.commit(type.loading, { key: 'complete', value: false });

          // Invoke gridApi.refreshClientSideRowModel('filter') in ag-Grid
          EventBus.getInstance().dispatch('refreshClientSideRowModel', { filter: true });

          AlertUtils.showSuccess(
            `${i18n.t('raptor.operatorLanding.successfullyCompletedOperation', {
              '0': payload.data.rowData['MFGITM__MFGNUM_0'],
              '1': payload.data.rowData['ZOPEWRKF__OPENUM_0'],
              '2': payload.data.rowData['ZOPEWRKF__OPESPLNUM_0'],
            })}`
          );
          resolve(response);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'complete', value: false });
          AlertUtils.showError(error);
          reject(error);
        });
    });
  },

  /**
   * Complete without operation track
   * @param context
   * @param payload
   */
  completeWithoutOperationTrack(context, payload) {
    const i18n = ApplicationContext.getI18n();
    const validationService = new RaptorOperatorLandingValidationService(i18n);

    return new Promise((resolve, reject) => {
      const method = i18n.t('raptor.operatorLanding.complete');

      const validator = validationService.complete(payload);
      if (validator.fails()) {
        throw new Error(`${method}:<br>${RaptorOperatorLandingValidationService.errorsMessage(validator)}`);
      }

      context.commit(type.updateCompletedQuantity, {
        parentIndex: payload.data.indexes.parent,
        childIndex: payload.data.indexes.child,
        completedQuantity: payload['CPLQTY_0'],
      });
      context.commit(type.loading, { key: 'complete', value: false });

      // Invoke gridApi.refreshClientSideRowModel('filter') in ag-Grid
      EventBus.getInstance().dispatch('refreshClientSideRowModel', { filter: true });

      AlertUtils.showSuccess(
        `${i18n.t('raptor.operatorLanding.successfullyCompletedOperation', {
          '0': payload.data.rowData['MFGITM__MFGNUM_0'],
          '1': payload.data.rowData['ZOPEWRKF__OPENUM_0'],
          '2': payload.data.rowData['ZOPEWRKF__OPESPLNUM_0'],
        })}`
      );
    });
  },

  skip(context, payload) {
    const i18n = ApplicationContext.getI18n();

    return new Promise((resolve, reject) => {
      context.commit(type.loading, { key: 'skip', value: true });
      const track: ICustomOperationTrack = FromOperatorLandingLine(payload.data.rowData, payload['CPLQTY_0'], null);
      api
        .skipOperation(track)
        .then(response => {
          context.commit(type.skip, {
            parentIndex: payload.data.indexes.parent,
            childIndex: payload.data.indexes.child,
            completedQuantity: payload['CPLQTY_0'],
          });
          context.commit(type.loading, { key: 'skip', value: false });

          // Invoke gridApi.refreshClientSideRowModel('filter') in ag-Grid
          EventBus.getInstance().dispatch('refreshClientSideRowModel', { filter: true });

          AlertUtils.showSuccess(
            `${i18n.t('raptor.operatorLanding.successfullySkippedOperation', {
              '0': payload.data.rowData['MFGITM__MFGNUM_0'],
              '1': payload.data.rowData['ZOPEWRKF__OPENUM_0'],
              '2': payload.data.rowData['ZOPEWRKF__OPESPLNUM_0'],
            })}`
          );
          resolve(response);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'skip', value: false });
          AlertUtils.showError(error);
          reject(error);
        });
    });
  },

  //
  // Actions on multiple lines
  //

  /**
   * Complete operation(s) for the selected lines
   *
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  completeAll(context, payload) {
    const i18n = ApplicationContext.getI18n();

    return new Promise((resolve, reject) => {
      const list = payload.list;

      context.commit(type.loading, { key: 'complete', value: true });
      api
        .trackMultipleOperation(
          list.map(item => {
            return FromOperatorLandingLine(item.data, item['CPLQTY_0'], null);
          })
        )
        .then(response => {
          context.commit(type.completeAll, { list });
          context.commit(type.loading, { key: 'complete', value: false });

          if (list.length === 1) {
            AlertUtils.showSuccess(
              `${i18n.t('raptor.operatorLanding.successfullyCompletedOperation', {
                '0': list[0].data['MFGITM__MFGNUM_0'],
                '1': list[0].data['ZOPEWRKF__OPENUM_0'],
                '2': list[0].data['ZOPEWRKF__OPESPLNUM_0'],
              })}`
            );
          } else {
            AlertUtils.showSuccess(`${i18n.t('raptor.operatorLanding.successfullyCompletedMultipleOperation')}`);
          }
          resolve(list);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'complete', value: false });
          AlertUtils.showError(error);
          reject(error);
        });
    });
  },

  /**
   * Skip operation(s) for the selected lines
   *
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  skipAll(context, payload) {
    const i18n = ApplicationContext.getI18n();

    return new Promise((resolve, reject) => {
      const list = payload.list;

      context.commit(type.loading, { key: 'skip', value: true });
      api
        .skipMultipleOperation(
          list.map(item => {
            return FromOperatorLandingLine(item.data, item['CPLQTY_0'], null);
          })
        )
        .then(response => {
          context.commit(type.skipAll, { list });
          context.commit(type.loading, { key: 'skip', value: false });

          if (list.length === 1) {
            AlertUtils.showSuccess(
              `${i18n.t('raptor.operatorLanding.successfullySkippedOperation', {
                '0': list[0].data['MFGITM__MFGNUM_0'],
                '1': list[0].data['ZOPEWRKF__OPENUM_0'],
                '2': list[0].data['ZOPEWRKF__OPESPLNUM_0'],
              })}`
            );
          } else {
            AlertUtils.showSuccess(`${i18n.t('raptor.operatorLanding.successfullySkippedMultipleOperation')}`);
          }
          resolve(list);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'skip', value: false });
          AlertUtils.showError(error);
          reject(error);
        });
    });
  },

  /**
   * Reset operation(s) for the selected lines
   */
  resetAll(context, payload) {
    const i18n = ApplicationContext.getI18n();

    return new Promise((resolve, reject) => {
      const list = payload.list;

      context.commit(type.loading, { key: 'complete', value: true });
      api
        .deleteMultipleOperation(
          list.map(item => {
            return {
              mfgnum0: item.data['MFGITM__MFGNUM_0'],
              openum0: item.data['ZOPEWRKF__OPENUM_0'],
              opesplnum0: item.data['ZOPEWRKF__OPESPLNUM_0'],
            };
          })
        )
        .then(response => {
          context.commit(type.resetAll, { list });
          context.commit(type.loading, { key: 'complete', value: false });

          if (list.length === 1) {
            AlertUtils.showSuccess(
              `${i18n.t('raptor.operatorLanding.successfullyResetOperation', {
                '0': list[0].data['MFGITM__MFGNUM_0'],
                '1': list[0].data['ZOPEWRKF__OPENUM_0'],
                '2': list[0].data['ZOPEWRKF__OPESPLNUM_0'],
              })}`
            );
          } else {
            AlertUtils.showSuccess(`${i18n.t('raptor.operatorLanding.successfullyResetMultipleOperation')}`);
          }

          resolve(list);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'complete', value: false });

          AlertUtils.showError(error);
          reject(error);
        });
    });
  },

  /**
   * Resume operation(s) for the selected lines
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  resumeAll(context, payload) {
    const i18n = ApplicationContext.getI18n();

    return new Promise((resolve, reject) => {
      const list = payload.list;

      context.commit(type.loading, { key: 'skip', value: true });
      api
        .deleteMultipleSkippedOperationTrack(
          list.map(item => {
            return {
              mfgnum0: item.data['MFGITM__MFGNUM_0'],
              openum0: item.data['ZOPEWRKF__OPENUM_0'],
              opesplnum0: item.data['ZOPEWRKF__OPESPLNUM_0'],
            };
          })
        )
        .then(response => {
          context.commit(type.resumeAll, { list });
          context.commit(type.loading, { key: 'skip', value: false });

          if (list.length === 1) {
            AlertUtils.showSuccess(
              `${i18n.t('raptor.operatorLanding.successfullyResumeOperation', {
                '0': list[0].data['MFGITM__MFGNUM_0'],
                '1': list[0].data['ZOPEWRKF__OPENUM_0'],
                '2': list[0].data['ZOPEWRKF__OPESPLNUM_0'],
              })}`
            );
          } else {
            AlertUtils.showSuccess(`${i18n.t('raptor.operatorLanding.successfullyResumeMultipleOperation')}`);
          }

          resolve(list);
        })
        .catch(error => {
          context.commit(type.loading, { key: 'skip', value: false });
          AlertUtils.showError(error);
          reject(error);
        });
    });
  },
};

/**
 * Synchronous process
 */
const mutations = {
  manufacturingPlan(state, payload) {
    state.manufacturingPlan = payload.manufacturingPlan;
  },

  rowData(state, payload) {
    state.rowData = payload;
  },

  workCenter(state, payload) {
    state.workCenter = payload;
  },

  updateCompletedQuantity(state, payload) {
    state.rowData[payload.parentIndex].children[payload.childIndex]['ZMFGOPETRK__ZAMTCPLQTY_0'] += payload.completedQuantity;
  },

  skip(state, payload) {
    const item = state.rowData[payload.parentIndex].children[payload.childIndex];
    item['ZMFGOPETRK__ZAMTSKPCPLQTY_0'] += payload.completedQuantity;
  },

  completeAll(state, payload) {
    payload.list.forEach(item => {
      item.data['ZMFGOPETRK__ZAMTCPLQTY_0'] += item['CPLQTY_0'];
    });
  },

  skipAll(state, payload) {
    payload.list.forEach(item => {
      item.data['ZMFGOPETRK__ZAMTSKPCPLQTY_0'] += item['CPLQTY_0'];
    });
  },

  resetAll(state, payload) {
    payload.list.forEach(item => (item.data['ZMFGOPETRK__ZAMTCPLQTY_0'] = item['CPLQTY_0']));
  },

  resumeAll(state, payload) {
    payload.list.forEach(item => (item.data['ZMFGOPETRK__ZAMTSKPCPLQTY_0'] = item['CPLQTY_0']));
  },

  loading(state, payload) {
    state.loading[payload.key] = payload.value;
  },

  /**
   * Add items to the beginning of an array.
   * @param state
   * @param payload
   */
  addTag(state, payload) {
    while (state.tags.length >= state.tagLimit) {
      state.tags.pop();
    }
    state.tags.unshift(payload);
  },
};

export const operatorLandingStore: Module<OperatorLandingStateStorable, any> = {
  state: { ...defaultOperatorLandingState },
  getters,
  actions,
  mutations,
  namespaced: true,
};
