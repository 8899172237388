// import axios from 'axios'; // SPE
import axios, { AxiosStatic } from 'axios';
import { ERP_HEADERS } from '@gv/ammo-astra';

const TIMEOUT = 1000000;
const onRequestSuccess = config => {
  config.timeout = TIMEOUT;
  config.url = `${SERVER_API_URL}${config.url}`;
  return config;
};
const setupAxiosInterceptors = (onUnauthenticated, onServerError) => {
  const onResponseError = err => {
    const status = err.status || err.response.status;
    if (status === 403 || status === 401) {
      return onUnauthenticated(err);
    }
    if (status >= 500) {
      return onServerError(err);
    }
    return Promise.reject(err);
  };

  if (axios.interceptors) {
    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(res => res, onResponseError);
  }
};

export { onRequestSuccess, setupAxiosInterceptors };

// SPE Begin

/**
 * Singleton class we have added below.
 */
export class AxiosSingleton {
  private static instance: AxiosStatic;

  private constructor() {}

  // Ensure that there is only one instance created
  public static getInstance(): AxiosStatic {
    if (!AxiosSingleton.instance) {
      AxiosSingleton.instance = axios;

      // ERP base headers
      axios.defaults.headers[ERP_HEADERS.TENANT_ID] = ERP_TENANT_ID;
    }
    return AxiosSingleton.instance;
  }
}

// SPE End
